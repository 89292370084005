import React, { useEffect } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

const Disclaimer = () => {
  useEffect(() => {
    window.location.replace(
      'https://app.termly.io/document/disclaimer/d609d0f6-4bd6-4f30-97cc-4393ee2e4692'
    )
  }, [])

  return null
}

export default Disclaimer
